<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="40"
          v-model="categoryCustomer.name" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="saveCustomerCategory" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveCustomerCategory" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import CategoryCustomer from "@/components/modules/crm/category-customer/CategoryCustomer.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CategoryCustomerCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText },
  data() {
    return {
      formName: "categoryCustomerCreateUpdate",
      categoryCustomer: new CategoryCustomer(),
      urlCreate: "/api/v1/crm/category-customer/create",
      urlUpdate: "/api/v1/crm/category-customer/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "hideModal", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveCustomerCategory() {
      if (this.categoryCustomer.id) {
        let params = { url: this.urlUpdate, obj: this.categoryCustomer };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["saveCustomerCategory"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.categoryCustomer };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);
          }
          this.removeLoading(["saveCustomerCategory"]);
        });
      }
    },
    update(data) {
      this.categoryCustomer = new CategoryCustomer();
      this.categoryCustomer.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "customerCategoryCreate") {
          this.resetValidation(this.formName);
          this.categoryCustomer = new CategoryCustomer();
        }
        if (event.name == "customerCategoryUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
